import React, { useEffect } from 'react';
import { zEprefill, zEactivate } from '../middleware/zE';

export function Zendesk() {
    
    var interval1 = setInterval(() => {
        zEactivate();
        if(document.querySelector("#webWidget")?.contentDocument.querySelector("#widgetHeaderTitle+button")) {
            if(document.querySelector("#webWidget").contentDocument.querySelector("#widgetHeaderTitle+button").style.visibility !== "hidden") {
                document.querySelector("#webWidget").contentDocument.querySelector("#widgetHeaderTitle+button").style.visibility = "hidden";
            } else {
                clearInterval(interval1);
            }
        }
    }, 100);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        zEprefill({ name: urlParams.get('name'), email: urlParams.get('email') });
    }, [])

    return (
        <></>
    );
}