import './App.css';
import { Zendesk } from './pages/Zendesk';

function App() {
  return (
    <div>
      <Zendesk />
    </div>
  );
}

export default App;
